/** General
  *** Variables
    */
    var touch = /(iphone|ipod|ipad|android|iemobile|blackberry|bada)/.test(window.navigator.userAgent.toLowerCase())
    var IEorEdge = (function () {
      var result = 0
      var ua = window.navigator.userAgent
      var matches = [
        ['MSIE '],
        ['Trident/', 'rv:'],
        ['Edge/']
      ]
      for (var i = 0; i < matches.length; i++) {
        var str = matches[i][0]
        var match = ua.indexOf(str)
        if (match !== -1) {
          if (matches[i].length > 1) {
            str = matches[i][1]
            match = ua.indexOf(str)
          }
          var from = match + str.length
          result = parseInt(ua.substring(from, ua.indexOf('.', from)), 10)
          break
        }
      }
      return result
    })()
    var safari = (function () {
      var ua = window.navigator.userAgent
      return (ua.indexOf('Safari') !== -1 && ua.indexOf('AppleWebKit') !== -1 && ua.indexOf('Chrome') === -1 && ua.indexOf('Android') === -1)
    })()

    var $doc = $(document)
    var $win = $(window)
    var $html = $('html')
    var $body = $('body').addClass('js' + (touch ? ' touch' : '') + (IEorEdge ? (IEorEdge < 12 ? ' IE' : ' Edge') + ' IEdge' : '') + (safari ? ' safari' : ''))

    var $this
    var $parent

  /** Functions
    */
    Object.size = function (obj) {
      var size = 0
      for (var key in obj) if (obj.hasOwnProperty(key)) size++
      return size
    }

    function selection() {
      return (window.getSelection ? window.getSelection().toString() : document.selection && document.selection.type !== 'Control' ? document.selection.createRange().text : '')
    }

    function set (el, str) {
      if (el instanceof jQuery || el.constructor.prototype.jquery) $this = el
      else $this = $(el)
      if (str) $parent = $this.parents(str).eq(0)
      else $parent = undefined
    }

    function ucfirst (str) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    }

    var is = {
      str: function (e) {
        if (typeof e === 'string') return true
        return false
      },
      undef: function (e) {
        if (typeof e === 'undefined') return true
        return false
      }
    }

    function reflow (el) {
      if (is.undef(el)) el = document.documentElement
      else if (el instanceof jQuery || el.constructor.prototype.jquery) el = el[0]
      void(el.offsetHeight)
    }

    $.ajaxSetup({
      headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
      }
    })

    function regrow (data) {
      var oldHeight = data.$parent.outerHeight()
      data.$parent.css('height', oldHeight).addClass('transition').data('busy', true)
      if (typeof data.$this === 'function') data.$this = data.$this()
      reflow(data.$parent)
      var newHeight = (data.extra || 0)
      switch (data.type) {
        case 'this':
          newHeight += data.$this.outerHeight(true)
          break
        case 'siblings':
          data.$siblings = data.$this.siblings()
          for (var i = 0; i < data.$siblings.length; i++) newHeight += data.$siblings.eq(i).outerHeight(true)
          break
        case 'all':
          data.$all = data.$this.siblings().addBack()
          for (var i = 0; i < data.$all.length; i++) newHeight += data.$all.eq(i).outerHeight(true)
          break
        default:
          break
      }
      if (oldHeight !== newHeight) {
        data.$parent.css('height', newHeight)
        var end = (data.end || 'transition') + 'end'
        data.$parent.on(end, function (e) {
          if (e.target === this) data.$parent.off(end).removeClass('transition').css('height', 'auto').data('busy', false)
          recalc()
        })
      } else {
        data.$parent.removeClass('transition').css('height', 'auto').data('busy', false)
      }
    }

/** Parallax
  */
  var $scroller = $('#scroller')
  var $parallax
  var requestID = 0
  var height

  $.fn.scrollStopped = function (callback) {
    var $this = $(this)
    $this.scroll(function (e) {
      clearTimeout($this.data('scrollTimeout'))
      $this.data('scrollTimeout', setTimeout(callback.bind(this), 150, e))
    }.bind(this))
  }

  ;(function () {
    var bodyHeight = 0
    function watchBody () {
      var newHeight = $scroller.outerHeight(true)
      if (newHeight !== bodyHeight) {
        bodyHeight = newHeight
        $body.css('height', newHeight)
      }
      requestAnimationFrame(watchBody)
    }
    if (!touch) requestAnimationFrame(watchBody)
  })()

  function recalc (resize) {
    height = window.innerHeight
    if ($parallax) {
      $parallax.each(function () {
        var $this = $(this)
        var p = {type: $this.data('parallax')}
        p['offset'] = $this.offset().top
        p['height'] = $this.outerHeight()
        $this.data('p', p)
      })
      if (!requestID) requestID = requestAnimationFrame(function (timestamp) {
        animate(timestamp, true)
      })
    }
  }

  function animate (timestamp, once) {
    var scrollY = window.scrollY || window.pageYOffset || document.documentElement.scrollTop
    if (!touch) $scroller.css('transform', 'translate3d(0, -' + scrollY + 'px, 0)')
    if ($parallax.length) {
      $parallax.each(function () {
        var $this = $(this)
        var p = $this.data('p')
        var distance = (p.offset - scrollY + p.height / 2 - height / 2) / (p.height / 2 + height / 2)
        if (!(distance > 1 || distance < -1)) {
          switch (p.type) {
            default:
            case 'bg':
              $this.css('transform-origin', '50% ' + (distance / 2 + 0.5) * 100 + '% 0')
              break
          }
        }
      })
    }
    requestID = (once ? 0 : requestAnimationFrame(animate))
  }

  $win.resize(function () {
    requestAnimationFrame(function () {
      recalc(true)
    })
  }).scroll(function () {
    if (!requestID) {
      requestID = requestAnimationFrame(animate)
    }
  }).scrollStopped(function () {
    if (requestID) {
      cancelAnimationFrame(requestID)
      requestID = 0
    }
  })

/** Navigation
  */
  var busy
  var $nav = $('#main-navigation')
  var $underline = $('#underline')
  var $active = []
  var $hamburger = $nav.find('.hamburger')
  $hamburger.on('click', function () {
    if ($hamburger.toggleClass('active').hasClass('active')) {
      $win.on('resize.hamburger', function () {
        if (window.innerWidth > 768) {
          $hamburger.removeClass('active')
          $win.off('resize.hamburger')
        }
      })
    } else {
      $win.off('resize.hamburger')
    }
  })
  var nav = (function () {
    var options = {
      base: '/',
      container: $scroller.children('main')
    }

    var titles = {}
    var inRoot = false

    function parse (p) {
      if (is.str(p)) p = {href: p}
      if (p.href.indexOf(options.base) === 0) p.href = p.href.substr(options.base.length)
      p.href = p.href.replace(/^\/|\/$/g, '')
      if (p.href === '') p.root = true
      p.el = options.container.children(p.root ? '[pathtype="root"]' : '[pathname="' + p.href + '"]')
      if (p.el.length) {
        if (p.root) p.href = p.el.attr('pathname').replace(/^\/|\/$/g, '')
        else if (p.el.attr('pathtype') === 'root') p.root = true
      }
      if (!(p.root && inRoot)) {
        if (p.root) inRoot = true
        else inRoot = false
        if (p.type === 'replace' || !p.el.length || p.el.hasClass('refresh')) {
          busy = true
          $.ajax({
            url: 'ajax/' + p.href,
            dataType: 'json',
            success: function (response, textStatus, request) {
              if (typeof response !== 'object') {
                a.lert('Er ging iets fout bij het ophalen van de pagina.')
                busy = false
                return
              }
              p.title = response.title
              p.new = response.html
              setState(p)
              busy = false
            },
            error: function (request, textStatus, errorThrown) {
              a.lert('Er ging iets fout bij het ophalen van de pagina.')
              busy = false
            }
          })
        } else {
          p.title = titles[options.base + p.href]
          setState(p)
        }
      }
    }

    function setState (p) {
      window.scrollTo(0, 0)
      if (!touch) $scroller.css('transform', 'translate3d(0, 0, 0)')
      if (p.new) {
        options.container.children().addClass('inActive').end().append(p.new)
        p.el.remove()
      } else {
        p.el.removeClass('inActive').siblings().addClass('inActive')
      }
      if (p.href === '') p.href = options.container.children(':not(.inActive)').attr('pathname')
      var pathname = options.base + p.href
      if (p.type !== 'replace') history.pushState({}, '', pathname)
      document.title = titles[pathname] = p.title
      $('header').find('li').removeClass('active').has('> a[href="/' + p.href + '"], ' + (p.root ? '> a[href="/"]' : '> a[href="/' + p.href.split('/')[0] + '"]')).addClass('active')
      if (window.outerWidth > 768) {
        if ($active.length) {
          var $a = $active.children('a')
          reflow(
            $underline.css({
              'transform': 'translateX(' + ($active.position().left + $a.position().left) + '%) scaleX(' + ($a.width() / 100) + ')'
            }).removeClass('invisible')
          )
        }
        var fade = $active.length
      }
      $active = $nav.find('li.active')
      if (window.outerWidth > 768) {
        if ($active.length ) {
          var $a = $active.children('a')
          reflow(
            $underline.css({
              'transform': 'translateX(' + ($active.position().left + $a.position().left) + '%) scaleX(' + ($a.width() / 100) + ')'
            }).one('transitionend', function () {
              $underline.addClass('invisible')
            })
          )
          if (!fade) $underline.addClass('active')
        } else if (fade) $underline.removeClass('active')
      }
      reflow()
      $parallax = options.container.children(':not(.inActive)').find('.parallax')
      recalc()
    }

    $doc.on('click', 'a', function (e) {
      set(this)
      var href = $this.attr('href')
      if (href.charAt(0) === '/' && href.indexOf('.') === -1) {
        e.preventDefault()
        if (!busy) {
          $hamburger.removeClass('active')
          parse(href)
        }
      }
    })

    $(window).on('popstate', function () {
      parse({href: window.location.pathname, type: 'replace'})
    })

    parse({href: window.location.pathname, type: 'replace'})

    return parse
  })()

/** Alert
  */
  var a = (function () {
    var standard = {
      type: 'error', /* Standard types include 'error' and 'success' */
      header: 'Oeps...', /* Text only */
      main: 'Er is iets fout gegaan.' /* Text or HTML */
    }
    var transitions = {
      in: 300,
      out: 225,
      delay: 50
    }
    var $alert = $('#alert')
    var $header = $alert.find('header')
    var $main = $alert.find('main')
    var $footer = $alert.find('footer')
    var queue = []
    var timeout
    function shift () {
      clearTimeout(timeout)
      $alert.removeClass('active')
      $footer.off('click')
      queue.shift()
      if (queue.length) {
        setTimeout(function () {
          pop()
        }, (transitions.out || 0) + (transitions.delay || 0))
      }
    }
    function pop () {
      var data = queue[0]
      $alert.removeClass().addClass(data['type'] || standard['type'])
      $header.text(data['header'] || standard['header'])
      $main.html(data['main'] || standard['main'])
      var callbacks = data.callbacks
      if (callbacks) {
        var html = ''
        for (var callback in callbacks) html += '<div class="button" callback="' + callback + '">' + (callbacks[callback].text || ucfirst(callback)) + '</div>'
        $footer.html(html).one('click', '.button', function () {
          var obj = data.callbacks[$(this).attr('callback')]
          if (obj.func) obj.func()
          shift()
        }).addClass('active')
      } else {
        $footer.removeClass('active').empty()
        timeout = setTimeout(function () {
          shift()
        }, (transitions.in || 0) + 750 + $main.text().split(' ').length * 250)
      }
      $alert.addClass('active')
    }
    $doc.on('click', '#alert .close', function () { shift() })

    return {
      lert: function (data) {
        if (is.str(data)) data = {main: data}
        queue.push(data || {})
        if (queue.length === 1) pop()
      }
    }
  })()

/** UI Elements
  */
  var whitelist = [8, 9, 13, 37, 38, 39, 40, 46] // backspace, tab, enter, arrow keys, delete
  $doc.on('focus', '[contenteditable]', function () {
    set(this, '.number')
  }).on('keypress', '[contenteditable]', function (e) { // keypress is also triggered in Firefox when pressing tab, insert, delete, etc.
    var which = e.which
    if (whitelist.indexOf(e.keyCode) === -1 && $parent.length && !(new RegExp('[0-9' + ($parent.hasClass('decimal') ? '.' : '') + ']')).test(String.fromCharCode(which)) && !e.ctrlKey) e.preventDefault()
  }).on('paste drop', '[contenteditable]', function (e) {
    e.preventDefault()
    set(this, '.number')
    var oldText = $this.text()
    var newText = e.originalEvent[(e.type === 'paste' ? 'clipboardData' : 'dataTransfer')].getData('text/plain')
    if (!$parent.length || ($parent.length && !(/[^0-9.]/.test(newText) || (newText.split('.').length - 1 + ((e.type === 'paste' ? oldText.replace(selection(), '') : oldText).indexOf('.') !== -1 ? 1 : 0) > ($parent.hasClass('decimal') ? 1 : 0))))) document.execCommand('insertText', false, newText)
  }).on('input', '[contenteditable]', function () {
    if ($this.text() === '') $this.empty()
  })

  $doc.on('click', '.sendForm', function () {
    if (busy !== true) {
      busy = true
      set(this, 'form')
      var data = {
        module_id: $parent.attr('module_id')
      }
      var $inputs = $parent.find('.ui.input')
      var error = false
      for (var i = 0; i < $inputs.length; i++) {
        var $input = $inputs.eq(i)
        var $error = $input.siblings('.errorMessage')
        var name = $input.attr('name')
        var value = $input.find('[contenteditable]')[0].innerText
        if (value === '') {
          error = true
          regrow({
            type: 'all',
            $this: function () {
              return $error.text('Dit veld moet nog ingevuld worden.').addClass('visible active')
            },
            $parent: $error.parent(),
            end: 'animation'
          })
        } else if (/email/.test(name) && !/^.+\@\S+\.\S+$/.test(value)) {
          error = true
          regrow({
            type: 'all',
            $this: function () {
              return $error.text('Dit e-mailadres is ongeldig').addClass('visible active')
            },
            $parent: $error.parent(),
            end: 'animation'
          })
        } else {
          if ($error.hasClass('active')) {
            regrow({
              type: 'siblings',
              $this: function () {
                return $error.removeClass('active')
              },
              $parent: $error.parent(),
              end: 'animation'
            })
          }
        }
        data[name] = value
      }
      if (error !== true) {
        $.ajax({
          method: 'POST',
          url: $parent.attr('action'),
          data: data,
          dataType: 'json',
          success: function (response, textStatus, request) {
            $inputs.find('[contenteditable]').empty()
            a.lert({
              type: 'success',
              header: 'Verzonden',
              main: 'Uw bericht is verzonden!'
            })
            busy = false
          },
          error: function (request, textStatus, errorThrown) {
            a.lert('Er ging iets fout bij het verzenden van het bericht.')
            busy = false
          }
        })
      } else {
        busy = false
      }
    }
  })

/** Sliders
  *** Main
    */
    $doc.on('click', '.slider.main .dots li', function () {
      set(this, '.slider')
      var i = $this.index()
      var data = $parent.data()
      if (!data || (data.i !== i && !data.busy)) {
        $this.addClass('active').siblings().removeClass('active')
        regrow({
          type: 'this',
          $this: function () {
            return $parent.data('i', i).find('.slides li').eq(i).addClass('active').siblings().removeClass('active').end()
          },
          $parent: $parent,
          end: 'transition',
          extra: 15
        })
      }
    })

  /** Brands
    */
    $doc.on('click', '.slider.brands .prev, .slider.brands .next', function () {
      set(this, '.slider')
      var data = $parent.data()
      if ($.isEmptyObject(data)) data = {length: $parent.find('li').length - 4, i: 0, busy: false}
      if (!data['busy']) {
        $parent.data('busy', true)
        var className = $this.attr('class')
        var $ul = $parent.find('ul')
        if (className === 'next') {
          data['i']++
          if (data['i'] > data['length']) {
            data['i'] -= data['length']
            $ul.css({'transform': 'translate3d(0, 0, 0)'})
          }
        } else {
          data['i']--
          if (data['i'] === -1) {
            data['i'] += data['length']
            $ul.css({'transform': 'translate3d(' + (data['i'] + 1) * -100 + '%, 0, 0)'})
          }
        }
        reflow($ul)
        $parent.data(data)
        $ul.addClass('transition').one('transitionend', function () {
          $ul.removeClass('transition')
          $parent.data('busy', false)
        }).css({'transform': 'translate3d(' + data['i'] * -100 + '%, 0, 0)'})
      }
    })

/** Scrollbar styling
  */
  var rule = '#scrollbartest{overflow:scroll;width:40px;height:40px}#' + ' -webkit- -moz- -ms- -o- '.split(' ').join('scrollbar{width:0px}#scrollbartest::').split('#').slice(1).join('#') + 'scrollbar{width:0px}'
  $('body').append('<div id="scrollbartest"><style type="text/css">' + rule + '</style></div>')
  if ($('#scrollbartest').prop('scrollWidth') === 40) $('body').addClass('scrollbarStyles')
  $('#scrollbartest').remove()